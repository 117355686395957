.timeline {
  display: grid;
  gap: 1px;
  background-color: #f0f0f0;
  overflow-x: auto;
}

.timeline-header {
  display: contents;
}

.product-column,
.month-column {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.product-column {
  left: 0;
}

.product-row {
  display: contents;
}

.product-name {
  background-color: #fff;
  padding: 10px;
  grid-column: 1;
  position: sticky;
  left: 0;
  z-index: 1;
}

.stage-block {
  background-color: #e0e0e0;
  padding: 5px;
  text-align: center;
  font-size: 0.8em;
  border-radius: 4px;
  margin: 2px;
  white-space: nowrap;
}

.stage-block.vegetative {
  background-color: #a0d6b4;
}

.stage-block.reproductive {
  background-color: #f7d08a;
}

.timeline-row-container {
  border: 1px solid #f3f4f6;
  border-top: none;
  position: relative;
  margin-top: -3px;
}

.timeline-row {
  height: 104px;
  background-color: #fff;
  position: relative;
  margin-top: 3px
}

.timeline-text {
  border: none !important;
  text-align: left !important;
  padding: 6px !important;
}

.color-container {
  background: linear-gradient(
    90deg,
    rgb(20, 128, 60) 0%,
    rgb(223, 251, 232) 40.78%,
    rgb(255, 243, 221) 48.71%,
    rgb(234, 154, 34) 100%
  );
  padding: 0 !important;
  display: inline-flex !important;
  justify-content: space-around;
  flex-wrap: wrap;
}

.error-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 4px;
  padding: 12px 16px;
  border: 1px solid var(--Red-red-40, #F98989);
  background: var(--Red-red-10, #FFF1F1);
  font-size: 14px;
}

.error-message {
  display: flex;
  align-items: center;
}

.icon-error {
  margin-right: 12px;
}

.retry-text {
  cursor: pointer;
  color: var(--Neutral-neutral-60, #696F88);
  border: none;
  background-color: transparent;
}

@media screen and (max-width: 576px) {
  .timeline-row-container {
    border: none; 
  }
  .timelime-row-card  {    
    height: 76px;
  }
  .color-container {
    padding: 1px 10px !important;
    justify-content: end;
    gap: 35%;
  }
}
